import React, { Component, RefObject, FocusEvent, useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

import cn from 'classnames'
import './Search.css'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
// import { useComponentVisible } from '../DropDown/Dropdown';

export type SearchProps = {
  width?: 'small' | 'medium' | 'large' | 'full'
  type?: 'primary' | 'secondary' | 'tertiary'
  /**
   * "regular" or "oval"
   */
  corners?: 'regular' | 'oval'
  name?: string
  id?: string
  className?: string
  value?: string
  inputRef?: RefObject<HTMLInputElement>
  error?: boolean
  debounceSearch?: boolean
  onChange?: Function
  onClear?: boolean
  dynamicInput?: boolean
  myHookValue?: any
} & JSX.IntrinsicElements['input'] &
  typeof defaultProps

export interface SearchState {
  value?: string
}

const defaultProps = {
  disabled: false,
  // isReadOnly: false,
  width: 'medium',
  type: 'primary',
  corners: 'oval',
  debounceSearch: false,
  dynamicInput: false
};

function useComponentVisible(initialIsVisible: Boolean, props: any) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref: any = useRef(null);

  const handleClickOutside = (event: any) => {
    if (props.dynamicInput && (ref.current && !ref.current.contains(event.target)) || (document.activeElement === document.getElementById('sidebar-extension')) || (document.activeElement.clientWidth == 315)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useComponentVisible(!props.dynamicInput ? true : false, props);
    return <Component {...props} myHookValue={myHookValue} />;
  }
}

export class Search extends Component<SearchProps> {
  static defaultProps = defaultProps

  state = {
    value: this.props.value || ''
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  debounceSearch = debounce(() => {
    const value: any = this.state.value
    this.props.onChange(value)
  }, 500)

  onChange = e => {
    const value = e.target.value
    this.setState({ value })
    if (this.props.onChange) {
      if (this.props.debounceSearch) {
        this.debounceSearch()
        return
      }
      this.props.onChange(value)
    }
  }

  //todo what is the relevance of this logic?
  handleFocus = (e: FocusEvent) => {
    if (this.props.disabled) {
      ; (e.target as HTMLInputElement).select()
    }
  }

  hanldeClearSearch = (e: any) => {
    const value: any = '';
    this.setState({
      value
    })
    this.props.onChange(value);
  }

  handleToggleInput = (event: any) => {
    if (this.props.dynamicInput) {
      this.props.myHookValue.setIsComponentVisible(!this.props.myHookValue.isComponentVisible)
    }
  }

  render() {
    const {
      className,
      placeholder,
      maxLength,
      disabled,
      onChange,
      onBlur,
      error,
      width,
      value,
      type,
      name,
      id,
      inputRef,
      corners = 'oval',
      debounceSearch,
      onClear,
      dynamicInput,
      myHookValue,
      ...otherProps
    } = this.props;

    const { ref, isComponentVisible } = myHookValue;

    const widthClass = `Search--${width}`
    const typeClass = `Search--${type}`
    const classNames = cn(['Search'], className, [widthClass], [typeClass], {
      ['Search--disabled']: disabled,
    })
    return (
      <div ref={!this.state.value ? ref : null} className={classNames}>
        <div onClick={this.handleToggleInput} className={`${this.props.dynamicInput ? 'ml-3' : ''} ${!isComponentVisible && this.props.dynamicInput ? "Search__icon-wrapper" : ""}`}>
          <Icon className={`Search__search-icon ${!isComponentVisible && this.props.dynamicInput ? 'Search__search-icon__hidden' : ''}`} icon="Search" size="tiny" />
        </div>
        {/* {isComponentVisible ? */}
        <div className={isComponentVisible ? 'Search-input-show' : 'Search-input-hide'}>
          <input
            className={cn('Search__input', {
              'regular-corners': corners === 'regular',
            })}
            id={id}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={this.handleFocus}
            onChange={this.onChange}
            value={this.state.value}
            autoFocus={this.props.dynamicInput ? true : false}
            type={type}
            ref={inputRef}
            {...otherProps}
          />
          <div onClick={this.hanldeClearSearch} className="Search__cancel-icon">
            {(onClear && this.state.value) &&
              <Tooltip content="Cancel Search" position="top">
                <Icon icon="Cancel" size="tiny" />
              </Tooltip>
            }
          </div>
        </div>
        {/* : null */}
        {/* } */}
      </div>
    )
  }
}

export default withMyHook(Search)


// UNSAFE_componentWillReceiveProps(nextProps: SearchProps) {
//   if (this.props.value !== nextProps.value) {
//     this.setState({
//       value: nextProps.value,
//     })
//   }
// }

// <div className={classNames}>
//     {type && type === 'primary' && (
//         <div className='Search__primary'>
//             <input
//                 className={'Search__input'}
//                 id={id}
//                 name={name}
//                 placeholder={placeholder}
//                 maxLength={maxLength}
//                 disabled={disabled}
//                 onBlur={onBlur}
//                 onFocus={this.handleFocus}
//                 onChange={e => {
//                     if (disabled) return;
//                     if (onChange) {
//                         onChange(e);
//                     }
//                     this.setState({ value: e.target.value });
//                 }}
//                 value={this.state.value}
//                 type={type}
//                 ref={inputRef}
//                 {...otherProps}
//             />
//             <Icon icon="Search" size="tiny" />
//         </div>
//     )}
// </div>

//   return (
//     <div className={classNames}>
//       <input
//         className={cn('Search__input', {
//           'regular-corners': corners === 'regular',
//         })}
//         id={id}
//         name={name}
//         placeholder={placeholder}
//         maxLength={maxLength}
//         disabled={disabled}
//         onBlur={onBlur}
//         onFocus={this.handleFocus}
//         onChange={this.onChange}
//         value={this.state.value}
//         type={type}
//         ref={inputRef}
//         {...otherProps}
//       />
//       <Icon className="Search__search-icon" icon="Search" size="tiny" />
//       <div onClick={this.hanldeClearSearch} className="Search__cancel-icon">
//         {(onClear && this.state.value) &&
//           <Tooltip content="Cancel Search" position="top">
//             <Icon icon="Cancel" size="tiny" />
//           </Tooltip>
//         }
//       </div>
//     </div>
//   )