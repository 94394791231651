import React from 'react';
import cn from 'classnames';

import Icon from '../Icon2/Icon';

import './EmptyState.css';


type imgSrcProps = {
  alt?: string | undefined
  src: string
};

export type emptyStateProps = {
  className?: string
  children?: React.ReactNode
  description?: string | React.ReactNode
  displayImage?: boolean
  heading?: string | React.ReactNode
  img?: React.ReactNode
  imgSrc?: imgSrcProps
  forPage?: 'card' | 'list' | 'fullPage' | 'empty'
  moduleIcon?: string
  actions?: React.ReactNode,
  footer?: React.ReactNode,
  type?: 'primary' | 'secondary' | 'tertiary'
  subType?: 'large' | 'small',
  width?: number | string | undefined
};

type customImageProps = {
  img: React.ReactNode | undefined
  imgSrc: imgSrcProps | undefined
};

const CustomImage: React.FunctionComponent<customImageProps> = props => {
  const { img, imgSrc } = props;
  const src = imgSrc !== undefined ? imgSrc.src : undefined;
  const alt = imgSrc !== undefined ? imgSrc.alt : undefined;

  return (
    img !== undefined ? <>{img}</> : <img src={src} alt={alt} className="Error-image" />
  );
};

const EmptyState = (props: emptyStateProps) => {
  const {
    heading,
    description,
    img,
    imgSrc,
    className,
    type,
    moduleIcon,
    forPage,
    actions,
    subType,
    footer,
    width
  } = props;

  // const displayImage = props.displayImage !== undefined ? props.displayImage : true
  // const children = props.children !== undefined ? props.children : null
  const displayImage = props.displayImage;
  const children = props.children;

  const classNames = cn('EmptyState', className, [`EmptyState--${type}`]);
  const wrapperClassNames = cn(type === 'secondary' || type === 'primary' ? 'EmptyStateWrapper flex-center' : '', [`EmptyStateWrapper--${forPage}`])
  const customStyles: any = {};

  if (width) {
    customStyles.maxWidth = width;
  }

  return (
    <div className={wrapperClassNames}>
      <div className={classNames} style={customStyles}>
        {/* <div className={`${displayImage === true ? 'emptyState-wrapper' : 'no-image'}`}> */}
        {displayImage === true ?
          <div className="EmptyState__image">
            {
              img !== undefined || imgSrc !== undefined ? <CustomImage img={img} imgSrc={imgSrc} /> : null
            }
          </div>
          : null
        }
        {moduleIcon !== undefined ? <Icon icon={moduleIcon} /> : null}
        {heading !== undefined ? <div className={`EmptyState__title ${subType ? 'EmptyState__title--' + subType : ''}`}>{props.heading}</div> : null}
        {description !== undefined ? <div className="EmptyState__description">{props.description}</div> : null}
        {actions !== undefined ? <div className="EmptyState__actions">{props.actions}</div> : null}
        {footer !== undefined ? <div className="EmptyState__footer">{props.footer}</div> : null}
        {children !== null ? children : null}
      </div >
    </div>
  );
};

EmptyState.defaultProps = {
  displayImage: true,
  children: null,
  type: 'primary'
} as Partial<emptyStateProps>;

export default EmptyState;