import React, { Component } from 'react'
import './SkeletonTile.css'

export type SkeletonTileProps = {
  numberOfTiles: number
  tileHeight: number
  tileWidth: number
  tileBottomSpace: number
  tileTopSpace: number
  tileleftSpace: number
  tileRadius?: number
  animate?: boolean
}

export class SkeletonTile extends Component<SkeletonTileProps> {
  render() {
    const {
      numberOfTiles,
      tileleftSpace,
      tileTopSpace,
      tileHeight,
      tileBottomSpace,
      tileWidth,
    } = this.props

    const animate = this.props.animate !== undefined ? this.props.animate : true
    const radius = this.props.tileRadius !== undefined ? this.props.tileRadius : 7

    return (
      <React.Fragment>
        <svg
          height={
            numberOfTiles * tileHeight +
            numberOfTiles * tileBottomSpace +
            numberOfTiles * tileTopSpace
          }
          width={tileWidth + tileleftSpace}
          className="SkeletonTile__svg"
          fill="#EDF1F7"
        >
          {Array.from(Array(numberOfTiles)).map((_, index) => (
            <g key={index}>
              <rect
                x={tileleftSpace}
                y={index * (tileHeight + tileBottomSpace) + tileTopSpace}
                rx={radius}
                // ry="6"
                width={tileWidth}
                height={tileHeight}
              />
            </g>
          ))}
        </svg>
      </React.Fragment>
    )
  }
}

export default SkeletonTile
