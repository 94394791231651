import React, { MouseEventHandler, RefObject, CSSProperties } from 'react'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import './Checkbox.css'
import cn from 'classnames'
import Icon from '../Icon2/Icon'

const defaultProps = {
  indeterminate: false,
  required: false,
  disabled: false,
  enableStopPropagation: false
}

export interface CheckboxState {
  checked?: boolean
  indeterminate?: boolean
  disabled?: boolean
  value?: string
}

export type CheckboxProps = {
  className?: string
  checked?: boolean
  indeterminate?: boolean
  /**
   * specifies that an checkbox input field must be filled out before submitting the form
  */
  required?: boolean
  /**
   * is checkbox full width selectable
  */
  isLabelFullWidth?: boolean
  /**
   * text on click of which it check/uncheck
  */
  label?: string | React.ReactNode
  /**
   * The name prop specifies the name attribute of an checkbox input element
  */
  name?: string
  /**
   *The id prop specifies the id attribute of an checkbox input element 
  */
  id?: string
  /**
   *specifies if a checkbox is in checked state when the form is submitted 
  */
  value?: any
  /**
   *determines whether checkbox is disabled or not 
  */
  disabled?: boolean
  /**
   *displays button type checkbox 
  */
  isButton?: boolean
  /**
   *Action that want to perform onclick of checkbox 
  */
  onClick?: MouseEventHandler
  /**
   *The onchange event occurs when the value of an element has been changed
  */
  onChange?: any
  style?: CSSProperties
  /**
   *The inputRef will add ref to the checkbox input field 
  */
  inputRef?: RefObject<HTMLInputElement>
  /**
   *specifies the tab order of an checkbox element 
  */
  tabIndex?: any
  testId?: string
  enableStopPropagation?: boolean
} & typeof defaultProps

class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  static defaultProps = defaultProps

  render() {
    let {
      id,
      className,
      name,
      value,
      label,
      checked,
      indeterminate,
      isLabelFullWidth,
      onClick,
      onChange,
      required,
      disabled,
      inputRef,
      isButton,
      testId,
      enableStopPropagation,
      ...otherProps
    } = this.props

    const classNames = cn('Checkbox', className, {
      'Checkbox--state-checked': checked,
      'Checkbox--state-disabled': disabled
    })

    return (
      <>
        {isButton ? (
          <div data-test-id={testId || 'cs-checkbox'} className={`ButtonCheckbox ${classNames}`} tabIndex={this.props.tabIndex}>
            <input
              id={id}
              type="checkbox"
              name={name}
              value={value}
              checked={checked}
              onClick={e => {
                if (enableStopPropagation) {
                  e.stopPropagation();
                }
                if (!disabled && onClick) {
                  onClick(e)
                }
              }}
              required={required}
              disabled={disabled}
              onChange={e => {
                if (!disabled && onChange) {
                  onChange(e)
                }
              }}
              ref={inputRef}
              {...otherProps}
            />
            <label
              htmlFor={id}
              className="Checkbox__label"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {label}
            </label>
          </div>
        ) : (
          <label data-test-id={testId || 'cs-checkbox'} className={classNames} id={id}>
            <input
              id={id}
              type="checkbox"
              name={name}
              value={value}
              checked={checked}
              onClick={e => {
                if (enableStopPropagation) {
                  e.stopPropagation();
                }
                if (!disabled && onClick) {
                  onClick(e)
                }
              }}
              required={required}
              disabled={disabled}
              onChange={e => {
                if (!disabled && onChange) {
                  onChange(e)
                }
              }}
              ref={inputRef}
              {...otherProps}
            />
            <span
              className="Checkbox__tick"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <Icon icon={`${disabled ? 'CheckboxDisabled' : 'CheckboxActive'}`} />
            </span>
            <span
              className="Checkbox__box"
              onClick={e => {
                e.stopPropagation()
              }}
            ></span>
            <span
              className={`Checkbox__label ${isLabelFullWidth === true ? 'Checkbox--select-fullWidth' : ''}`}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {label}
            </span>
          </label>
        )}
      </>
    )
  }
}

export default withDeprecatedProp(Checkbox, { 'fullWidth': 'isLabelFullWidth', 'text': 'label', 'button': 'isButton' })
