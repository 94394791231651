import React, { Component } from 'react'
import cn from 'classnames'

import './FieldLabel.css'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  requiredText: '*',
  required: false,
  testId: 'cs-field-label'
}

export type FieldLabelProps = {
  /**
  * Sets or returns the value of the for attribute of a label. It specifies to which form element the label is bound to
  */
  htmlFor: string
  /**
  * Pass the class names to be appended to this prop
  */
  className?: string
  /**
  * Pass an ID that you can use for testing purposes. It is applied as a data attribute (data-test-id)
  */
  testId?: string
  /**
  * Sets whether it is mandatory to input FieldLabel
  */
  required?: boolean
  /**
  * Pass a custom message that can be added instead of the default text
  */
  requiredText?: string,
  /**
  * Indicates an error
  */
  error?: boolean
  /**
  * Sets it in the disabled state
  */
  disabled?: boolean
  children?: React.ReactNode

} & Partial<DefaultProps>

export class FieldLabel extends Component<FieldLabelProps> {
  static defaultProps = defaultProps

  render() {
    const { className, children, htmlFor, required, requiredText, error, disabled, testId, ...otherProps } = this.props

    const classNames = cn(
      'FieldLabel',
      className,
      { 'FieldLabel--disabled': disabled }
    )

    return (
      <label className={classNames} htmlFor={htmlFor} {...otherProps} data-test-id={testId}>
        {children}
        {required && !!requiredText.length && (
          <span className="FieldLabel__required-text">
            <span className={`${error ? 'FieldLabel__required-text--error' : ''}`}>{requiredText}</span>
          </span>
        )}
      </label>
    )
  }
}

export default FieldLabel
