import React, { useState, useEffect, useRef, ReactElement } from 'react'
import TextInput from '../TextInput/TextInput'
import cn from 'classnames'
import Icon from '../Icon2/Icon'
import ActionTooltip from '../ActionTooltip/ActionTooltip'
import './Dropdown.css'

export type IList = {
  label: String | React.ReactNode
  action?: Function
  default?: boolean
  searchKey?: string
  value?: string | React.ReactNode | Element,
  showAsActive?: Boolean
  textLabel?: String //required only when label is html
  disable?: boolean
  closeDropdownOnSelect?: boolean
}

type IDropdown = {
  list: Array<IList>
  type: 'click' | 'hover' | 'select'
  children?: React.ReactNode | Element
  withSearch?: Boolean
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  className?: string
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  closeAfterSelect?: boolean
  isEllipse?: boolean
  isMultiCheck?: boolean
  title?: string
  searchPlaceholder?: string
  onChange?: (data?: any) => void
  viewAs?: 'label' | 'value'
  highlightActive?: Boolean
  headerLabel?: string
  arrowSecondary?: Boolean
  emptyPlaceholder?: string
  adjustWidthForContent?: boolean
  withIcon?: boolean
  maxWidth?: string | number,
  onListScroll?: any
}

type IDropdownOption = {
  list: Array<IList>
  children?: React.ReactNode | Element
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  dropDownType?: 'primary' | 'secondary' | 'tertiary'
  classNames?: string
  closeAfterSelect?: boolean
  isEllipse?: boolean
  isMultiCheck?: boolean
  title?: string
  searchPlaceholder?: string
  arrowSecondary?: Boolean
  emptyPlaceholder?: string
  adjustWidthForContent?: boolean
  withIcon?: boolean
  maxWidth?: string | number
  onListScroll?: any
}

type ISelectDropdown = {
  list: Array<IList>
  withSearch?: Boolean
  dropDownPosition?: 'top' | 'bottom' | 'left' | 'right'
  withArrow?: boolean
  classNames?: string
  dropDownType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  closeAfterSelect?: boolean
  title?: string
  searchPlaceholder?: string
  onChange?: (data?: any) => void
  viewAs: 'label' | 'value'
  highlightActive?: Boolean
  headerLabel?: string
  arrowSecondary?: Boolean
  emptyPlaceholder?: string
  adjustWidthForContent?: boolean
  withIcon?: boolean
  maxWidth?: string | number
  onListScroll?: any
}

export function useComponentVisible(initialIsVisible: Boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref: any = useRef(null)
  const menuRef: any = useRef(null);
  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    }
  }

  const handleClickOutside = (event: any) => {
    if ((ref.current && !ref.current.contains(event.target)) || (document.activeElement === document.getElementById('sidebar-extension')) || (document.activeElement.clientWidth == 315)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, menuRef, isComponentVisible, setIsComponentVisible }
}

const ClickableDropDown: React.FunctionComponent<IDropdownOption> = ({
  list,
  children,
  dropDownPosition,
  dropDownType,
  withArrow,
  arrowSecondary,
  emptyPlaceholder,
  title,
  searchPlaceholder,
  withIcon,
  classNames,
  closeAfterSelect,
  isEllipse,
  isMultiCheck,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
}) => {
  const { ref, menuRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  let labelType: any = list[0] ? list[0].label : ''

  let checkboxLabel: any = `Dropdown__menu__list ${isMultiCheck ? 'Dropdown__menu__list__checkbox' : ''
    }`

  useEffect(() => {
    if (isComponentVisible) {
      const isInside = () => {
        if (menuRef.current) {
          const rect = menuRef.current.getBoundingClientRect()
          const windowHeight = window.innerHeight
          const windowWidth = window.innerWidth
          const isInsideHeight = rect.top >= 0 && rect.bottom <= windowHeight
          const isInsideWidth = rect.left >= 0 && rect.right <= windowWidth
          console.log(rect.right, rect, windowWidth)
          return {
            inside: isInsideHeight && isInsideWidth,
            diff: {
              bottom: windowHeight - rect.bottom,
              right: windowWidth - rect.right,
            }
          }
        }
        return {
          inside: false,
          diffe: {}
        }
      }
      const { inside, diff } = isInside();
      if (!inside) {
        if (diff.bottom < 0) {
          menuRef.current.style.top = `${menuRef.current.offsetTop + diff.bottom}px`
        }
        if (diff.right < 0) {
          menuRef.current.style.left = `${menuRef.current.offsetLeft + diff.right}px`
        }
      }
      // console.log('visible bro', ref.current, menuRef.current, isInside())
    }
  }, [isComponentVisible]);

  return (
    <div
      className={`${classNames} ${isComponentVisible ? 'Dropdown--open' : ''}`}
      ref={ref}
      onClick={(evt: any) => {
        evt.stopPropagation()
        setIsComponentVisible(!isComponentVisible)
      }}
    >
      <div className={`${'Dropdown__header'} ${isEllipse ? 'Dropdown__header--ellipses' : ''}`}>
        {isEllipse ? (
          <>
            {/* {isComponentVisible ? (
              <Icon icon="DefaultEnv" size="medium" />
            ) : (
              <Icon icon="SeeMore" size="original" />
            )} */}
            <Icon icon="SeeMore" size="original" />
          </>
        ) : (
          <>
            {children}
            {withArrow && (
              <Icon
                className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''
                  }`}
                icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
                size="original"
              />
            )}
          </>
        )}
      </div>
      {isComponentVisible && (
        <div
          ref={menuRef}
          className={
            dropDownType === 'secondary'
              ? 'Dropdown__menu--secondary'
              : dropDownType === 'tertiary'
                ? 'Dropdown__menu--tertiary'
                : 'Dropdown__menu--primary'
          }
          style={{
            ...(adjustWidthForContent && { width: 'min-content' }),
            ...(maxWidth && { maxWidth: maxWidth, minWidth: 0 }),
          }}
        >
          {title ? <div className="Dropdown__menu_title">{title}</div> : null}
          <ul className={checkboxLabel} onScroll={onListScroll && onListScroll}>
            {list.map((item: any, index: any) => {
              return (
                <li
                  key={index}
                  className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${withIcon ? 'Dropdown__menu__list__item-icon' : ''}`}
                  onClick={(evt: any) => {
                    evt.stopPropagation()
                    // evt.preventDefault();
                    // const { closeDropdownOnSelect = true } = item;
                    if (closeAfterSelect) {
                      setIsComponentVisible(!isComponentVisible)
                    }
                    if (item.action) {
                      item.action()
                    }
                  }}
                >
                  {item.label}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

const HoverDropDown: React.FunctionComponent<IDropdownOption> = ({
  list,
  children,
  dropDownPosition,
  withIcon,
  dropDownType,
  title,
  searchPlaceholder,
  withArrow,
  arrowSecondary,
  classNames,
  closeAfterSelect,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  return (
    <div
      ref={ref}
      className={`${classNames} ${isComponentVisible ? 'Dropdown--open' : ''}`}
      onMouseEnter={() => {
        setIsComponentVisible(true)
      }}
      onMouseLeave={() => {
        setIsComponentVisible(false)
      }}
    >
      <div className="Dropdown__header">
        {children}
        {withArrow && (
          <Icon
            className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
            icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
            size="original"
          />
        )}
      </div>
      {isComponentVisible && (
        <div
          className={
            dropDownType === 'secondary'
              ? 'Dropdown__menu--secondary'
              : dropDownType === 'tertiary'
                ? 'Dropdown__menu--tertiary'
                : 'Dropdown__menu--primary'
          }
          style={{
            ...(adjustWidthForContent && { width: 'min-content' }),
            ...(maxWidth && { maxWidth: maxWidth, minWidth: 0 }),
          }}
        >
          <ul className="Dropdown__menu__list" onScroll={onListScroll && onListScroll}>
            {list.map((item: any, i: any) => {
              return (
                <li
                  key={i}
                  className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${withIcon ? 'Dropdown__menu__list__item-icon' : ''}`}
                  onClick={() => {
                    if (closeAfterSelect) {
                      setIsComponentVisible(!isComponentVisible)
                    }
                    if (item.action) {
                      item.action()
                    }
                  }}
                >
                  {item.label}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

const SelectDropDown: React.FunctionComponent<ISelectDropdown> = ({
  list,
  withSearch,
  dropDownPosition,
  withArrow,
  arrowSecondary,
  withIcon,
  emptyPlaceholder,
  classNames,
  dropDownType,
  title,
  searchPlaceholder,
  closeAfterSelect,
  onChange,
  viewAs,
  highlightActive,
  headerLabel,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
}) => {
  const getDefaultValue = (list: any) => {
    let default_element: any
    list.map((list_element: any) => {
      if (list_element.hasOwnProperty('default')) {
        if (list_element.default) {
          default_element = list_element
        }
      }
    })
    if (default_element) {
      if (default_element[viewAs]) {
        return default_element[viewAs]
      } else {
        return default_element.label ? default_element.label : default_element.value
      }
    }
  }

  const getDefaultLabelText = (list: any) => {
    let default_element: any
    list.map((list_element: any) => {
      if (list_element.hasOwnProperty('default')) {
        if (list_element.default) {
          default_element = list_element
        }
      }
    })
    if (default_element) {
      if (default_element[viewAs]) {
        return default_element.textLabel || default_element[viewAs]
      } else {
        return default_element.label ? default_element.textLabel || default_element.label : default_element.value
      }
    }
  }

  const [searchText, setSearchText] = useState('')
  const [selectedData, setSelectedData] = useState(getDefaultValue(list))
  const [selectedDataText, setSelectedDataText] = useState(getDefaultLabelText(list))
  const [searchList, setSearchList] = useState([])
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  const handleChange = (evt: any) => {
    if (evt.target.value.length > 0) {
      let newSearchList: any = []
      list.map((el: any) => {
        if (el.hasOwnProperty('searchKey') && typeof el.searchKey === 'string') {
          if (el.searchKey.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        } else if (typeof el.label === 'string') {
          if (el.label.toLowerCase().includes(evt.target.value.toLowerCase())) {
            newSearchList.push(el)
          }
        }
      })
      setSearchList(newSearchList)
    }
    setSearchText(evt.target.value)
  }

  const handleSelect = (itemAction: any, index: any) => {
    if (searchText.length > 0) {
      let chkSearchList: any = [...searchList]
      if (chkSearchList[index].label !== selectedData) {
        setSelectedData(chkSearchList[index].label)
        // console.log("called 3 ", chkSearchList[index]);
        if (chkSearchList[index].textLabel) {
          // console.log("called 4 ", chkSearchList[index]);
          setSelectedDataText(chkSearchList[index].textLabel)
        }
        itemAction()
        onChange && onChange(chkSearchList[index])
      }
      setSearchText('')
    } else {
      let cbValue: any
      if (list[index][viewAs]) {
        if (list[index][viewAs] !== selectedData) {
          cbValue = list[index][viewAs]
          setSelectedData(list[index][viewAs])
          setSelectedDataText(list[index].textLabel)
          itemAction()
        }
      } else {
        if (list[index].label !== selectedData && !list[index].value) {
          cbValue = list[index].label
          setSelectedData(list[index].label)
          // console.log("called 5 ", list[index])
          if (list[index].textLabel) {
            // console.log("called 6 ", list[index])
            setSelectedDataText(list[index].textLabel)
          }
          itemAction()
        }
        else if (list[index].value && list[index].value !== selectedData) {
          cbValue = list[index].value
          setSelectedData(list[index].value)
          itemAction()
        }
      }
      if (onChange !== undefined) {
        onChange(list[index])
      }
    }
    if (closeAfterSelect) {
      setIsComponentVisible(!isComponentVisible)
    }
  };

  const getDropDownMenuType = () => {
    if (dropDownType === 'secondary') {
      return 'Dropdown__menu--secondary'
    }
    else if (dropDownType === 'tertiary') {
      return 'Dropdown__menu--tertiary'
    }
    else if (dropDownType === 'quaternary') {
      return 'Dropdown__menu--quaternary'
    }
    else {
      return 'Dropdown__menu--primary'
    }
  }

  useEffect(() => {
    setSelectedData(getDefaultValue(list))
  }, [list])

  useEffect(() => {
    if (searchText.length === 0) {
      setSearchList([])
    }
  }, [searchText]);
  return (
    <div ref={ref} className={`${classNames} ${isComponentVisible ? 'Dropdown--open' : ''}`}>
      <div
        onClick={() => { setIsComponentVisible(!isComponentVisible) }}
        className={`Dropdown__header ${dropDownType === 'tertiary' ? 'flex-justify' : ''} ${isComponentVisible ? 'Dropdown__header--max' : 'Dropdown__header--min'}`}
      >
        {headerLabel ?
          <div>
            {headerLabel && (
              <div className="Dropdown__header__label">{headerLabel}</div>
            )}
            <div className="Dropdown__header__value" title={selectedDataText || selectedData}>
              {selectedData}
            </div>
          </div> :
          <>
            {
              (!selectedData && emptyPlaceholder) ?
                <div className="Dropdown__header__value">{emptyPlaceholder}</div>
                :
                <div className="Dropdown__header__value" title={selectedDataText || selectedData}>
                  {selectedData || searchPlaceholder}
                </div>
            }
          </>}
        <Icon
          className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
          icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
          size="original"
        />
      </div>
      {isComponentVisible && (
        <div
          className={getDropDownMenuType()}
          style={{
            ...(adjustWidthForContent && { width: 'min-content' }),
            ...(maxWidth && { maxWidth: maxWidth, minWidth: 0 }),
          }}
        >
          {dropDownType === 'tertiary' && (
            <div
              className="Dropdown__menu__header flex-justify"
              onClick={() => { setIsComponentVisible(!isComponentVisible) }}
            >
              <div className="Dropdown__menu__title">{title}</div>
              <Icon
                className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''
                  }`}
                icon={`${arrowSecondary ? 'DownArrowEnabled' : 'ChevronSmall'}`}
                size="original"
              />
            </div>
          )}
          {withSearch && (
            <div className="Dropdown__menu__searchBox">
              <Icon className="SearchIcon" icon="Search" size="original" />
              <TextInput
                placeholder={searchPlaceholder || 'Search here...'}
                type="search"
                value={searchText}
                onChange={handleChange}
              ></TextInput>
            </div>
          )}
          <ul
            className={`Dropdown__menu__list ${highlightActive ? 'Dropdown__menu__list--highlight-active' : ''}`}
            onScroll={onListScroll && onListScroll}
          >
            {searchText.length > 0 ? (
              searchList.length > 0 ? (
                searchList.map((item: any, i: any) => {
                  if (item.actionTooltip) {
                    return (
                      <ActionTooltip {...item.actionTooltipProps}>
                        <li
                          key={i}
                          // className="Dropdown__menu__list__item"
                          className={`Dropdown__menu__list__item ${item[viewAs] ? item[viewAs] === selectedData ? 'Dropdown__menu__list__item--active' : '' : item.label === selectedData && 'Dropdown__menu__list__item--active'}
                        ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} `}
                          onClick={evt => handleSelect(item.action ? item.action : () => { }, i)}
                          title={item.tooltip || ''}
                        >
                          {item.label}
                        </li>
                      </ActionTooltip>
                    )
                  }

                  return (
                    <li
                      key={i}
                      // className="Dropdown__menu__list__item"
                      className={`Dropdown__menu__list__item ${item[viewAs] ? item[viewAs] === selectedData ? 'Dropdown__menu__list__item--active' : '' : item.label === selectedData && 'Dropdown__menu__list__item--active'}
                        ${withIcon ? 'Dropdown__menu__list__item-icon' : ''} `}
                      onClick={evt => handleSelect(item.action ? item.action : () => { }, i)}
                      title={item.tooltip || ''}
                    >
                      {item.label}
                    </li>
                  )
                })
              ) : (
                <div className="no-results">No Result Found</div>
              )
            ) : (
              list.map((item: any, i: any) => {
                if (item.actionTooltip) {
                  // console.log('item.acttiontooltipProps', item.acttiontooltipProps)
                  return (
                    <ActionTooltip {...item.actionTooltipProps}>
                      <li
                        key={i}
                        // className="Dropdown__menu__list__item"
                        className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${item[viewAs] ? item[viewAs] === selectedData ? 'Dropdown__menu__list__item--active' : '' : item.label === selectedData && 'Dropdown__menu__list__item--active'} 
                        ${withIcon ? 'Dropdown__menu__list__item-icon' : ''}`}
                        onClick={evt => handleSelect(item.action ? item.action : () => { }, i)}
                        title={item.tooltip || ''}
                      >
                        {item.label}
                      </li>
                    </ActionTooltip>
                  )
                }

                return (
                  <li
                    key={i}
                    // className="Dropdown__menu__list__item"
                    className={`Dropdown__menu__list__item ${item.disable ? 'Dropdown__menu__list__item--disable' : ''} ${item[viewAs] ? item[viewAs] === selectedData ? 'Dropdown__menu__list__item--active' : '' : item.label === selectedData && 'Dropdown__menu__list__item--active'} 
                        ${withIcon ? 'Dropdown__menu__list__item-icon' : ''}`}
                    onClick={evt => handleSelect(item.action ? item.action : () => { }, i)}
                    title={item.tooltip || ''}
                  >
                    {item.label}
                  </li>
                )
              })
            )}
          </ul>
        </div>
      )}
    </div>
  )

  // return (
  //   <div ref={ref} className={`${classNames} ${isComponentVisible ? 'Dropdown--open' : ''}`}>
  //     <div
  //       onClick={() => { setIsComponentVisible(!isComponentVisible) }}
  //       className={`Dropdown__header ${dropDownType === 'tertiary' ? 'flex-justify' : ''}`}
  //     >
  //       {headerLabel && (
  //         <div className="Dropdown__header__label">{headerLabel}</div>
  //       )}
  //       <div className="Dropdown__header__value" title={selectedData}>
  //         {selectedData}
  //       </div>
  //       <Icon
  //         className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''}`}
  //         icon="ChevronSmall"
  //         size="original"
  //       />
  //     </div>
  //     {isComponentVisible && (
  //       <div
  //         className={getDropDownMenuType()}
  //       >
  //         {dropDownType === 'tertiary' && (
  //           <div
  //             className="Dropdown__menu__header flex-justify"
  //             onClick={() => { setIsComponentVisible(!isComponentVisible) }}
  //           >
  //             <div className="Dropdown__menu__title">{title}</div>
  //             <Icon
  //               className={`ml-5 Dropdown__chevron ${isComponentVisible === true ? 'toggle-icon' : ''
  //                 }`}
  //               icon="ChevronSmall"
  //               size="original"
  //             />
  //           </div>
  //         )}
  //         {withSearch && (
  //           <div className="Dropdown__menu__searchBox">
  //             <Icon className="SearchIcon" icon="Search" size="original" />
  //             <TextInput
  //               placeholder={searchPlaceholder || 'Search stacks'}
  //               type="search"
  //               value={searchText}
  //               onChange={handleChange}
  //             ></TextInput>
  //           </div>
  //         )}
  //         <ul className={`Dropdown__menu__list ${highlightActive ? 'Dropdown__menu__list--highlight-active' : ''}`}>
  //           {searchText.length > 0 ? (
  //             searchList.length > 0 ? (
  //               searchList.map((item: any, i: any) => {
  //                 return (
  //                   <li
  //                     key={i}
  //                     // className="Dropdown__menu__list__item"
  //                     className={`Dropdown__menu__list__item ${item[viewAs] ? item[viewAs] === selectedData ? 'Dropdown__menu__list__item--active' : '' : item.label === selectedData && 'Dropdown__menu__list__item--active'} `}
  //                     onClick={evt => handleSelect(item.action ? item.action : () => { }, i)}
  //                   >
  //                     {item.label}
  //                   </li>
  //                 )
  //               })
  //             ) : (
  //                 <div className="no-results">No Result Found</div>
  //               )
  //           ) : (
  //               list.map((item: any, i: any) => {
  //                 return (
  //                   <li
  //                     key={i}
  //                     // className="Dropdown__menu__list__item"
  //                     className={`Dropdown__menu__list__item ${item[viewAs] ? item[viewAs] === selectedData ? 'Dropdown__menu__list__item--active' : '' : item.label === selectedData && 'Dropdown__menu__list__item--active'} `}
  //                     onClick={evt => handleSelect(item.action ? item.action : () => { }, i)}
  //                   >
  //                     {item.label}
  //                   </li>
  //                 )
  //               })
  //             )}
  //         </ul>
  //       </div>
  //     )}
  //   </div>
  // )
}

const getDropDown = ({
  list,
  children,
  type,
  withSearch,
  dropDownPosition,
  withArrow,
  arrowSecondary,
  className,
  dropDownType,
  withIcon,
  title,
  emptyPlaceholder,
  searchPlaceholder,
  closeAfterSelect,
  isEllipse,
  isMultiCheck,
  onChange,
  viewAs,
  highlightActive,
  headerLabel,
  adjustWidthForContent,
  maxWidth,
  onListScroll,
}: IDropdown) => {
  const TypeClass = `Dropdown--${dropDownType}`
  const Position = `Dropdown--position-${dropDownPosition}`
  const classNames = cn('Dropdown', [TypeClass], [Position], className)

  switch (type) {
    case 'click': {
      return (
        <ClickableDropDown
          list={list}
          children={children}
          dropDownPosition={dropDownPosition}
          withArrow={withArrow}
          withIcon={withIcon}
          arrowSecondary={arrowSecondary}
          classNames={classNames}
          closeAfterSelect={closeAfterSelect}
          isEllipse={isEllipse}
          isMultiCheck={isMultiCheck}
          title={title}
          adjustWidthForContent={adjustWidthForContent}
          maxWidth={maxWidth}
          onListScroll={onListScroll && onListScroll}
        />
      )
    }
    case 'hover': {
      return (
        <HoverDropDown
          list={list}
          children={children}
          dropDownPosition={dropDownPosition}
          withArrow={withArrow}
          withIcon={withIcon}
          arrowSecondary={arrowSecondary}
          classNames={classNames}
          closeAfterSelect={closeAfterSelect}
          isEllipse={isEllipse}
          adjustWidthForContent={adjustWidthForContent}
          maxWidth={maxWidth}
          onListScroll={onListScroll && onListScroll}
        />
      )
    }
    case 'select': {
      return (
        <SelectDropDown
          list={list}
          withSearch={withSearch}
          dropDownPosition={dropDownPosition}
          withArrow={withArrow}
          arrowSecondary={arrowSecondary}
          classNames={classNames}
          withIcon={withIcon}
          dropDownType={dropDownType}
          title={title}
          searchPlaceholder={searchPlaceholder}
          closeAfterSelect={closeAfterSelect}
          onChange={onChange}
          viewAs={viewAs}
          highlightActive={highlightActive}
          headerLabel={headerLabel}
          emptyPlaceholder={emptyPlaceholder}
          adjustWidthForContent={adjustWidthForContent}
          maxWidth={maxWidth}
          onListScroll={onListScroll && onListScroll}
        />
      )
    }
  }
}

const Dropdown: React.FunctionComponent<IDropdown> = props => {
  const {
    list,
    children,
    type,
    withSearch,
    dropDownPosition,
    withArrow,
    withIcon,
    arrowSecondary,
    className,
    dropDownType,
    title,
    searchPlaceholder,
    emptyPlaceholder,
    closeAfterSelect,
    isEllipse,
    isMultiCheck,
    onChange,
    viewAs,
    highlightActive,
    headerLabel,
    adjustWidthForContent,
    maxWidth,

    onListScroll,
  } = props

  return (
    <>
      {getDropDown({
        list,
        children,
        type,
        withSearch,
        dropDownPosition,
        withArrow,
        withIcon,
        arrowSecondary,
        className,
        dropDownType,
        title,
        searchPlaceholder,
        closeAfterSelect,
        isEllipse,
        isMultiCheck,
        onChange,
        viewAs,
        highlightActive,
        headerLabel,
        emptyPlaceholder,
        adjustWidthForContent,
        maxWidth,

        onListScroll,
      })}
    </>
  )
}

Dropdown.defaultProps = {
  dropDownPosition: 'bottom',
  withSearch: false,
  withArrow: false,
  withIcon: false,
  dropDownType: 'primary',
  closeAfterSelect: false,
  isEllipse: false,
  isMultiCheck: false,
  arrowSecondary: false,
  viewAs: 'label',
  highlightActive: false,
  adjustWidthForContent: false
} as Partial<IDropdown>

export default Dropdown