import React, { useState } from 'react';
import copy from 'copy-to-clipboard';

import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';

import './ClipBoard.css';

export type clipBoardProps = {
  children?: React.ReactNode
  copyText: string
  copyLabel?: string
  className?: string
  displayText?: string
  position?: string
  type?: string
  withArrow?: boolean
};

type clipFunction = {
  copyText: string
  onCopy: Function
};

export const csClipBoard = ({ copyText, onCopy }: clipFunction) => {
  // const copyCallback = onCopy
  copy(copyText, {
    onCopy: onCopy()
  });
};

const ClipBoard = (props: clipBoardProps) => {
  const [copyStat, setCopyStat] = useState(false);
  // const withArrow = props.withArrow !== undefined ? props.withArrow : true (Dont remove)
  // const withArrow = false;
  // const position = props.position !== undefined ? props.position : 'right'
  // const copyLabel = props.copyLabel !== undefined ? props.copyLabel : null

  const withArrow = props.withArrow;
  const position = props.position;
  const copyLabel = props.copyLabel;

  const handleClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault()
    copy(props.copyText);
    setCopyStat(true);
  };

  const handleReset = () => {
    setCopyStat(false);
  };

  const displayComponent = () => {
    if (props.children === undefined) {
      switch (props.type) {
        case 'default':
          return (
            <>
              <Tooltip
                content={copyStat ? 'Copied' : `${copyLabel === null ? 'Copy' : copyLabel}`}
                showArrow={withArrow}
                position={position}
                onHidden={handleReset}
                trigger={copyStat ? 'mouseenter' : 'click mouseenter'}
              >
                <div className="ClipBoard" onClick={handleClick}>
                  {props.displayText}
                </div>
              </Tooltip>
            </>
          );
        case 'button':
          return (
            <>
              <Tooltip
                content={copyStat ? 'Copied' : `${copyLabel === null ? 'Copy' : copyLabel}`}
                showArrow={withArrow}
                position={position}
                onHidden={handleReset}
                trigger={copyStat ? 'mouseenter' : 'click mouseenter'}
              >
                <Button onClick={handleClick}>{props.displayText}</Button>
              </Tooltip>
            </>
          );
        default:
          break;
      };
    } else if (props.children !== undefined) {
      return (
        <>
          <Tooltip
            content={copyStat ? 'Copied' : `${copyLabel === null ? 'Copy' : copyLabel}`}
            showArrow={withArrow}
            position={position}
            onHidden={handleReset}
            trigger={copyStat ? 'mouseenter' : 'click mouseenter'}
          >
            <div onClick={handleClick}>{props.children}</div>
          </Tooltip>
        </>
      );
    };
  };

  return <>{displayComponent()}</>
};

ClipBoard.defaultProps = {
  position: 'right',
  copyLabel: null,
  withArrow: false,
  type: 'default'
} as Partial<clipBoardProps>;

export default ClipBoard
