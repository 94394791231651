import React, { useState } from 'react'
import Select, { components } from 'react-select'

import Checkbox from '../Checkbox/Checkbox'
import ContentEditor from './ContentEditor'
import './Select.css'
import FieldLabel from '../FieldLabel/FieldLabel'
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash'

const SingleValue = (props: any) => {
  const options = props.getValue()

  const { updateOption, ...rest } = props

  return (
    <components.SingleValue {...rest}>
      {options.map((option: any, index: number) => {
        return (
          <ContentEditor
            key={index}
            value={option.label}
            option={option}
            updateOption={updateOption}
          />
        )
      })}
    </components.SingleValue>
  )
}

const MultiValueLabel = (props: any) => {
  const { updateOption, ...rest } = props

  return (
    <components.MultiValueLabel {...rest}>
      <ContentEditor value={rest.data.label} option={rest.data} updateOption={updateOption} />
    </components.MultiValueLabel>
  )
}

const MultiValueContainer = ({ children, ...props }) => {
  console.log("sssssss ", children, props);
  // const { getValue, hasValue } = props;
  const newChildren = cloneDeep(children);
  const nbValues = props.selectProps.value.length;
  newChildren[0] = `${nbValues} ${props.selectProps.selectedLabel || 'items selected'}`;

  if (nbValues < 2) {
    return (
      <div className="multi-value-wrapper">
        <components.MultiValueContainer {...props}>
          {children}
        </components.MultiValueContainer>
      </div>
    );
  }
  return (
    <div className="multi-value-wrapper">
      <components.MultiValueContainer {...props}>
        {<>
          <span className="multi-value-count">{newChildren[0]}</span>
          <span className="multi-value-list">{children}</span>
        </>}

      </components.MultiValueContainer>
    </div>
  );
};

// const MultiValueContainerCustom = ({ children, ...props }) => {
//   const { getValue, hasValue } = props;
//   console.log("sssssss ", children, props);
//   const newChildren = Object.assign({}, children);
//   const nbValues = getValue().length;
//   // if (nbValues > 1) {
//   //   newChildren[0] = `${nbValues} ${props.selectProps.selectedLabel || 'items selected'}`;
//   // }

//   if (!hasValue) {
//     return (
//       <components.MultiValueContainer {...props}>
//         {children}
//       </components.MultiValueContainer>
//     );
//   }
//   return (
//     <components.MultiValueContainer {...props}>
//       {children}
//     </components.MultiValueContainer>
//   );
// };

const getValueContainer = ({
  showAllSelectedItems: showAll,
  setShowAllSelectedItems: setShowAll,
  multiDisplayLimit
}: {
  showAllSelectedItems: boolean
  setShowAllSelectedItems: React.Dispatch<React.SetStateAction<boolean>>
  multiDisplayLimit: number
}) => {
  return ({ children, hasValue, ...props }) => {
    if (!hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>
    }

    const [selectedItems, otherChildren] = children
    const itemsToDisplay = showAll ? selectedItems : selectedItems.slice(0, multiDisplayLimit)
    const overflowCounter = selectedItems.length - multiDisplayLimit

    return (
      <components.ValueContainer {...props}>
        <div className="Select__value-container--custom">
          {itemsToDisplay}
          {!showAll && overflowCounter > 0 && (
            <button
              className="Select__see-more-button"
              id="see-more-button"
              onClick={() => {
                setShowAll(true)
              }}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {`+${overflowCounter} More`}
            </button>
          )}
          {showAll && overflowCounter > 0 && (
            <button
              className="Select__see-less-button"
              id="see-less-button"
              onClick={() => {
                setShowAll(false)
              }}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {`See Less`}
            </button>
          )}
          {otherChildren}
        </div>
      </components.ValueContainer>
    )
  }
}

/** Returns a custom option component to be sent to the react-select component */
const Option = ({
  classname,
}: {
  classname?: string,
}) => {

  // console.log('vivek select options', props);
  return (props: any) => {
    if (props.isMulti && props.selectProps.noCheckBox && props.selectProps.isNested) {
      return (
        <components.Option {...props} className={classname}>
          <div style={{ 'paddingLeft': `${props.data.depth * 10}px` }}>
            {props.children}
          </div>
        </components.Option>
      )
    }

    if (props.isMulti) {
      // console.log("aaaaa ", props.data.depth);
      // if (props.selectProps.hasAddOption) {
      //   return <div onClick={props.selectProps.addOption} className="Select__add-option"><components.Option {...props} /></div>
      // }
      let flag = false;
      let optionIdx = _.findIndex(props.options, { value: props.value });
      if (optionIdx > -1 && optionIdx !== 0 && props.data.depth === 0) {
        flag = true;
      }
      return (
        <components.Option {...props}>
          {props.selectProps.isNested ? <div style={{ 'paddingLeft': `${props.data.depth * 10}px` }}>
            <Checkbox checked={props.isSelected} text={props.children} fullWidth={true} />
          </div> :
            <Checkbox checked={props.isSelected} text={props.children} fullWidth={true} />
          }
        </components.Option>
      )
    }
    if (props.selectProps.isNested && !props.isMulti) {
      let flag = false;
      let optionIdx = _.findIndex(props.options, { value: props.value });
      if (optionIdx > -1 && optionIdx !== 0 && props.data.depth === 0) {
        flag = true;
      }

      return (
        <components.Option {...props}>
          <div style={{ 'paddingLeft': `${props.data.depth * 10}px` }}>
            {props.children}
          </div>
        </components.Option>
      )
    }
    return <components.Option {...props} className={classname} />
  }
}

const Menu = ({ innerRef, innerProps, isDisabled, children, selectProps }) => {
  console.log("innerProps ", innerProps, selectProps);
  return (
    <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
      {children}
      <div onClick={selectProps.addOption} className="Select__add-option flex-v-center">{selectProps.addOptionText}</div>
    </div>
  );
};

const customComponents = ({
  updateOption,
  canEditOption,
  showCount,
  addOption,
  showMore,
  optionClassname,
  decorators = {},
  isMulti,
  showAllSelectedItems,
  setShowAllSelectedItems,
  multiDisplayLimit,
}: {
  isMulti: boolean
  showAllSelectedItems: boolean
  setShowAllSelectedItems: React.Dispatch<React.SetStateAction<boolean>>
  multiDisplayLimit: number
  [key: string]: any
}) => {
  if (canEditOption) {
    return {
      SingleValue: (props: any) => <SingleValue {...props} updateOption={updateOption} />,
      MultiValueLabel: (props: any) => <MultiValueLabel {...props} updateOption={updateOption} />,
      Option: Option({ classname: optionClassname }),
    }
  } else if (showCount && !addOption) {
    return {
      MultiValueContainer,
      Option: Option({ classname: optionClassname }),
    }
    // } else if (showMore) {
    //   return {
    //     MultiValueContainerCustom,
    //     Option
    //   }
  } else if (addOption) {
    if (MultiValueContainer) {
      return {
        MultiValueContainer,
        Option: Option({ classname: optionClassname }),
        Menu
      }
    } else {
      return {
        Option: Option({ classname: optionClassname }),
        Menu
      }
    }
  } else if (Object.keys(decorators).length) {
    const { CustomDropdownIndicator } = decorators
    return {
      Option: Option({ classname: optionClassname }),
      DropdownIndicator: (props) => {
        return (
          <components.DropdownIndicator {...props}>
            {CustomDropdownIndicator ? <CustomDropdownIndicator /> : props.children}
          </components.DropdownIndicator>
        )
      }
    }
  } else {
    if (isMulti && multiDisplayLimit) {
      return {
        Option: Option({ classname: optionClassname }),
        ValueContainer: getValueContainer({ showAllSelectedItems, setShowAllSelectedItems, multiDisplayLimit }),
      }
    }
    return {
      Option: Option({ classname: optionClassname })
      // MultiValueContainer,
    }
  }
}

export type ISelectProps = {
  width?: string
  maxWidth?: string
  minWidth?: string
  name?: string
  isDisabled?: boolean
  isClearable?: boolean
  isMulti?: boolean
  isSearchable?: boolean
  placeholder?: string
  hideSelectedOptions?: boolean
  menuShouldScrollIntoView?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  menuIsOpen?: boolean
  maxMenuHeight?: number
  minMenuHeight?: number
  onChange: Function
  onBlur?: Function
  options: any[]
  value: any
  canEditOption?: boolean
  updateOption?: (IUpdateOption: { label: any; id: any }) => void
  selectLabel?: string,
  isNested?: boolean,
  showCount?: boolean,
  selectedLabel?: string,
  addOption?: Function,
  hasAddOption?: boolean,
  addOptionText?: React.ReactNode | Element
  getOptionLabel?: Function,
  getOptionValue?: Function,
  noCheckBox?: boolean,
  showMore?: boolean,
  className?: string,
  decorators?: object,
  noOptionsMessage?: Function
  error?: Boolean
  optionClassname?: string,
  testId?: string
  filterOption?: Function,
  isOptionSelected?: Function,
  multiDisplayLimit?: number
}


const SelectField: React.FunctionComponent<ISelectProps> = props => {

  const [showAllSelectedItems, setShowAllSelectedItems] = useState(false);

  const allComponents = customComponents({
    updateOption: props.updateOption,
    canEditOption: props.canEditOption,
    showCount: props.showCount,
    addOption: props.hasAddOption,
    showMore: props.showMore,
    decorators: props.decorators,
    optionClassname: props.optionClassname,
    isMulti: props.isMulti,
    showAllSelectedItems: showAllSelectedItems,
    setShowAllSelectedItems: setShowAllSelectedItems,
    multiDisplayLimit: props.multiDisplayLimit,
  })

  let widthStyleObj: any = {}
  if (props.width) {
    widthStyleObj = { width: props.width }
  } else {
    widthStyleObj = { maxWidth: props.maxWidth || '500px', minWidth: props.minWidth || '200px' }
  }

  return (
    <div
      className={`Select ${props.showMore ? 'Select--inline' : ''} ${props.hasAddOption ? 'Select--add-option' : ''} ${props.error ? 'Select--error' : ''} `}
      style={{ ...widthStyleObj }}
      data-test-id={props.testId || 'cs-select'}
    >
      {props.selectLabel && <FieldLabel htmlFor="selectLabel">{props.selectLabel}</FieldLabel>}

      <Select
        name={props.name}
        closeMenuOnSelect={!props.isMulti}
        isDisabled={props.isDisabled || false}
        isClearable={props.isClearable || false}
        isMulti={props.isMulti || false}
        isSearchable={props.isSearchable || false}
        placeholder={props.placeholder}
        hideSelectedOptions={props.hideSelectedOptions || false}
        closeMenuOnScroll={true}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={props.menuShouldScrollIntoView}
        menuPlacement={props.menuPlacement}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={props.maxMenuHeight || 200}
        minMenuHeight={props.minMenuHeight}
        onChange={(newValue: any) => props.onChange(props.isMulti ? newValue || [] : newValue)}
        onBlur={props.onBlur}
        options={props.options}
        value={props.value}
        components={allComponents}
        backspaceRemovesValue={false}
        classNamePrefix="Select"
        isNested={props.isNested}
        addOption={props.addOption}
        hasAddOption={props.hasAddOption}
        addOptionText={props.addOptionText}
        showCount={props.showCount}
        selectedLabel={props.selectedLabel}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        noCheckBox={props.noCheckBox}
        showMore={props.showMore}
        className={props.className}
        noOptionsMessage={props.noOptionsMessage}
        isOptionSelected={props.isOptionSelected}
        aria-label={'cs-select-aria'}
        filterOption={props.filterOption}
      />
    </div>
  )
}

export default React.memo(SelectField)
