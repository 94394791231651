import React, { createRef } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import './Tooltip.css'
import cn from 'classnames'
import Icon from '../Icon2'

interface ITooltip extends TippyProps {
  content: string | React.ReactNode | { text: string; icon: any }
  position: string
  showArrow?: boolean
  onCreate?: (data: any) => void
  trigger?: string
  onHidden?: () => any
  disabled?: boolean
  variantType?: 'dark' | 'light' | 'menuIcon'
  visible?: boolean,
  showClose?: boolean,
  onClose?: () => void,
  type?: 'primary' | 'secondary' | 'dynamic',
  maxWidth?: any,
  [propsKey: string]: any
}

const Tooltip: React.FunctionComponent<ITooltip> = (props: any) => {
  const node = createRef<HTMLInputElement>()
  const { content, position, children, isMenuIcon, visible, showClose, ...rest } = props
  const showArrow = props.showArrow !== undefined ? props.showArrow : false
  const variant = props.variantType || 'dark'
  const tooltipType = props.type !== undefined ? props.type : 'primary'
  const disabled = props.disabled ? props.disabled : false
  const interactive = typeof content === 'string' ? false : true;

  let className =
    variant === 'dark'
      ? 'tippy-box-dark'
      : tooltipType === 'primary'
        ? 'tippy-box-light tippy-box-light--primary'
        : `tippy-box-light tippy-box-light--${tooltipType}`
  className = variant === 'menuIcon' ? 'tippy-box-light tippy-box-light-menu' : className
  className = cn(className, props.className);

  const wrapperClassNames = cn('tippy-wrapper', { [`${PaddingClass[position]}`]: showArrow });

  const getContent = () => {
    if (variant === "menuIcon") {
      return getKeyIcon(content.text, content.icon);
    }

    if (showArrow) {
      return (
        <div className="flex">
          {content}
          <div className={`tippy-arrow-${variant} tippy-arrow-${variant}-${ArrowDirection[position]}`} />
        </div >
      );
    }

    return content;
  }

  const wrapWithClose = (content) => {
    return (
      <>
        <div className="flex-v-center">
          <div>{content}</div>
          <div className="closeContainer flex-v-center flex-h-center" onClick={() => props.onClose()}>
            <Icon icon='Cancel' />
          </div>
        </div>

      </>
    );
  };

  return (
    <Tippy
      content={showClose ? wrapWithClose(getContent()) : getContent()}
      className={className}
      placement={position}
      arrow={showArrow}
      interactive={interactive}
      disabled={disabled}
      visible={visible}
      {...rest}
    >
      <div ref={node} className={wrapperClassNames}>
        {children}
      </div>
    </Tippy>
  )
}

const getKeyIcon = (text: string, icon: any) => {
  return (
    <div className="flex-v-center">
      <div>{text}</div>
      <div className="KeyShortcut">{icon}</div>
    </div>
  )
}

enum ArrowDirection {
  "left-start" = "right-start",
  "left-end" = "right-end",
  "left" = "right",

  "right-start" = "left-start",
  "right-end" = "left-end",
  "right" = "left",

  "top-start" = "bottom-start",
  "top-end" = "bottom-end",
  "top" = "bottom",

  "bottom-start" = "top-start",
  "bottom-end" = "top-end",
  "bottom" = "top",
};

enum PaddingClass {
  "left-start" = "pl-10",
  "left-end" = "pl-10",
  "left" = "pl-10",

  "right-start" = "pr-10",
  "right-end" = "pr-10",
  "right" = "pr-10",

  "top-start" = "pt-10",
  "top-end" = "pt-10",
  "top" = "pt-10",

  "bottom-start" = "pb-10",
  "bottom-end" = "pb-10",
  "bottom" = "pb-10",
};

export default Tooltip
