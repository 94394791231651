import React from 'react'

const withDeprecatedProp = (WrappedComponent, depricatedPropList, depricatedComponentName?) => {
  const Component: any = (props: any) => {
    const updatedPropsList = { ...props }
    if (!(process.env.ENV !== 'production')) return WrappedComponent
    Object.keys(props).map((propKey: any) => {
      if (Object.keys(depricatedPropList).includes(propKey)) {
        updatedPropsList[depricatedPropList[propKey]] = updatedPropsList[propKey]
        delete updatedPropsList[propKey]
        console.warn(`${propKey} is depricated from the component instead use ${depricatedPropList[propKey]} as prop`)
      }
      return
    })
    if (depricatedComponentName) {
      if (depricatedComponentName[WrappedComponent.name] !== WrappedComponent.name) {
        console.warn(`${WrappedComponent.name} component name is depricated instead use ${depricatedComponentName[WrappedComponent.name]}`)
        return (
          <WrappedComponent
            {...updatedPropsList}
          />
        )
      }
    }
    return (
      <WrappedComponent
        {...updatedPropsList}
      />
    );
  };
  Component.displayName = WrappedComponent.name
  return Component
}
export default withDeprecatedProp