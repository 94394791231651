import React, { MouseEventHandler, RefObject } from 'react';

import './ToggleSwitch.css'

enum LabelPositions {
  left = "left",
  right = "right",
  top = "top",
  bottom = "bottom",
};

enum FlexDirection {
  left = "row",
  right = "row-reverse",
  top = "column",
  bottom = "column-reverse",
};

enum LabelColors {
  primary = "primary",
  secondary = "secondary"
}

export type ToggleSwitchProps = {
  /**
   * Used to identify the particular switch. Must be unique.
  */
  id?: string
  /**
   * The name attribute of the element.
  */
  name?: string
  /**
   * Boolean that decides whether the toggle switch should be on or off 
  */
  checked?: boolean
  /**
   * Boolean that decides whether toggle should be on or off upon rendering 
  */
  defaultChecked?: boolean
  /**
   * Used to get reference (ref) of the element in React. 
  */
  inputRef?: RefObject<HTMLInputElement>
  /**
   * Action that you wish to perform when the switch is turned on or off.
  */
  onChange?: any
  /**
   * Action that you wish to perform when the user clicks on the switch. 
  */
  onClick?: MouseEventHandler
  /**
   * Boolean that, if true, locks the switch in the current state. Use with `checked` to keep it turned on mandatorily.
  */
  disabled?: boolean
  /**
   * Set tab index for the element when user is navigating using the tab key. 
  */
  tabIndex?: any
  /**
   * Set the label for the toggle switch
  */
  label?: string
  /**
   * Set the label position for the toggle switch. Defaults to `right` 
  */
  labelPosition?: "left" | "right" | "top" | "bottom"
  /**
   * Set the label color for the toggle switch. Defaults to `primary` 
  */
  labelColor?: "primary" | "secondary"
  testId?: string
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
  const {
    id,
    name,
    checked,
    defaultChecked,
    inputRef,
    onChange,
    onClick,
    disabled,
    tabIndex,
    label,
    labelPosition,
    labelColor,
    testId
  } = props;

  return (
    <div
      data-test-id={testId || 'cs-toggle-switch'}
      style={label && {
        display: "inline-flex",
        flexDirection: getFlexDirectionForLabel(labelPosition),
        gap: "0.625rem",
        alignItems: "center"
      }}
    >

      {label &&
        <div className={`${checked && !disabled ? 'Label--color--primary' : 'Label--color--secondary'}`}> {label} </div>
        // <div className={`${checked ? 'Label--color--primary': 'Label--color--primary' } ${getLabelColorClass(labelColor)}`}> {label} </div>
      }

      <label className='toggle-switch'>
        <input
          type="checkbox"
          id={id}
          name={name}
          disabled={disabled}
          defaultChecked={defaultChecked}
          checked={checked}
          ref={inputRef}
          aria-label={name || 'aria-toggle-switch'} //used to avoid accessibility voilation.
          onChange={
            (e) => {
              if (!disabled && onChange) {
                onChange(e)
              }
            }
          }
          onClick={
            (e) => {
              if (!disabled && onClick) {
                onClick(e)
              }
            }
          }
        />
        <span
          className={`toggle-slider${disabled ? '--disabled' : ''} toggle-slider-round`}
          tabIndex={disabled ? -1 : tabIndex}
        />
      </label>
    </div>
  )
}

const getFlexDirectionForLabel = (labelPosition: string) => {
  if (labelPosition) {
    return FlexDirection[labelPosition];
  }

  return FlexDirection[LabelPositions.right];
}

const getLabelColorClass = (labelColor: string) => {
  if (labelColor) {
    return `Label--color--${labelColor}`
  }

  return `Label--color--${LabelColors.primary}`
}

export default ToggleSwitch;