import React, { useState, useEffect } from 'react';

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc';
import Icon from '../Icon2/Icon'

import './Tag.css'

type ITag = {
  tags: string[]
  isDisabled?: boolean
  onChange?: (tagToUpdate: string[]) => void
  label?: string
  testId?: string
}

export const Tag = (props: ITag) => {
  const tags = props.tags && props.tags.length ? props.tags : []
  const isDisabled = typeof props.isDisabled === 'boolean' ? props.isDisabled : false

  let [controlledTags, setControlledTags] = useState([])

  useEffect(() => {
    setControlledTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags.join(',')])

  const handleClick = (e, index) => {
    e.stopPropagation()
    const node = e.target
    if (node.id === 'remove_tag' || node.parentNode.id === 'remove_tag') {
      let filteredTags = controlledTags.filter((tag, i) => i !== index)
      props.onChange(filteredTags)
    }
  }

  return (
    <div data-test-id={props.testId}>
      {props.label && <p>{props.label}</p>}
      <div className={`Tag flex-wrap`}>
        {controlledTags.map((tag: string, index: number) => (
          <div
            key={index}
            className={`Tag__itemWrapper flex ${isDisabled ? 'Tag__item--disabled' : ''}`}
            onClick={
              isDisabled
                ? null
                : e => {
                  handleClick(e, index)
                }
            }
          >
            <Icon icon="MoveIcon" size="extraSmall" />
            <div className="Tag__item">
              <span className="Tag__item__name">{tag}</span>
              {!isDisabled &&
                // eslint-disable-next-line
                <a data-test-id={tag} className="Tag__item__close" id="remove_tag"><Icon icon="CancelSec" height="8" width="8" id="remove_tag" /></a>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Tag.defaultProps = {
  testId: 'cs-tags'
} as Partial<ITag>;

export const Tags = withDeprecatedProp(Tag, { 'updateTag': 'onChange', 'disabledTag': 'isDisabled' })

export default withDeprecatedProp(Tag, { 'updateTag': 'onChange', 'disabledTag': 'isDisabled' }, { 'Tag': 'Tags' })